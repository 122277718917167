<template>
    <div id="profile">
        <div v-if="state.stacksUser" class="wallet">
            <p>{{ stxAddress }}</p>
            <button @click="stacksLogout" class="button primary small">Logout</button>
            <button @click="state.showChecker = !state.showChecker" class="button action small">Want to check if an ID can claim?</button>
        </div>
        <div v-else>
            <button @click="stacksLogin" class="button primary">Login with Hiro Wallet</button>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import { isDev, state } from '@/main';
import { getShortStacksAddress } from '../helpers/getShortStacksAddress';
import {
    AppConfig,
    UserSession,
    showConnect,
} from "@stacks/connect";

export default {
    components: {},

    async setup() {
        const stxAddress = ref(null);
        const appConfig = new AppConfig(["store_write", "publish_data"]);
        const userSession = new UserSession({ appConfig });

        const updateStacksData = () => {
            state.userData = userSession.loadUserData() || null;
            state.stacksUser = isDev ? state.userData.profile.stxAddress.testnet : state.userData.profile.stxAddress.mainnet;
            stxAddress.value = getShortStacksAddress(state.stacksUser);
        };

        if (userSession.isUserSignedIn()) {
            updateStacksData();
        }

        const stacksLogin = () => {
            if (userSession.isSignInPending()) {
                userSession.handlePendingSignIn().then(() => {
                    updateStacksData();
                });

                return;
            }

            showConnect({
                appDetails: {
                    name: "Satoshibles",
                    icon: state.appIcon,
                },
                redirectTo: "/",
                onCancel: () => {
                    console.log('login cancelled');
                },
                onFinish: () => {
                    updateStacksData();
                },
                userSession: userSession,
            });
        };

        const stacksLogout = (e) => {
            e.stopPropagation();

            userSession.signUserOut();

            state.userData = null;
            state.stacksUser = null;
            stxAddress.value = null;
        };

        return {
            stacksLogin,
            stacksLogout,
            state,
            stxAddress
        };
    }
};
</script>