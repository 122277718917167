<template>
    <div>
        <div id="header">
            <div class="page-width">
                <router-link to="/">
                    <img src="../img/logo.png" alt="Monster Satoshibles" class="logo">
                </router-link>
                <suspense>
                    <Profile />
                </suspense>
            </div>
        </div>
        <suspense>
            <Progress v-if="state.stacksUser" />
        </suspense>
        <div id="page">
            <div class="page-width">
                <div v-if="state.stacksUser">
                    <Suspense>
                        <Claim />

                        <template #fallback>
                            <div class="loading-screen">
                                <Spinner size="large" />
                                {{ state.loadingMessage }} {{ state.loadingCurrent }} / {{ state.loadingTotal }}
                            </div>
                        </template>
                    </Suspense>
                </div>
                <div v-else class="login-message">
                    <img src="../img/arrow.png" alt="login">
                    Login to begin
                </div>
            </div>
        </div>
    </div>
    <div id="overlay" v-if="state.mintOver">
        <div class="content">
            <p class="icon">🎉</p>
            <h2>The Claim has ended!</h2>
            <p>The claim is over and all monsters have been revealed!</p>
            <p>Any remaining monsters will be minted by the project and we will decide what to do with them at a later date.</p>
            <p>You can now purchase monsters on the secondary market.</p>
            <div class="button-wrap">
                <p><a href="https://gamma.io/collections/satoshibles" class="button primary large" target="_blank">Buy Satoshibles on Gamma</a></p>
                <p><a href="https://gamma.io/collections/monster-satoshibles" class="button primary large" target="_blank">Buy Monsters on Gamma</a></p>
            </div>
        </div>
    </div>
    <div id="version">
        v0.1.6
    </div>
</template>

<script>
import Profile from '@/js/components/Profile';
import Claim from '@/js/components/Claim';
import { state } from '@/main';
import Spinner from '@/js/components/Spinner';
import Progress from '@/js/components/Progress';

export default {
    name: 'App',
    components: {
        Progress,
        Spinner,
        Profile,
        Claim
    },

    setup() {
        return {
            state
        };
    }
};
</script>

<style lang="scss">
@import "../css/index.scss";
</style>
