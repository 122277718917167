import { isDev, state } from '@/main';
import { StacksMainnet, StacksTestnet } from '@stacks/network';
import { callReadOnlyFunction, cvToJSON, uintCV } from "@stacks/transactions";

export const getOwner = async (contract, id) => {
    const activeNetwork = isDev ? new StacksTestnet({ url: state.stacksApi }) : new StacksMainnet({ url: state.stacksApi });
    const functionArgs = [uintCV(id)];

    const options = {
        contractAddress: contract.split(".")[0],
        contractName: contract.split(".")[1],
        functionName: "get-owner",
        network: activeNetwork,
        functionArgs,
        senderAddress: state.stacksUser,
    };

    const res = await callReadOnlyFunction(options);

    return cvToJSON(res).value.value.value;
};