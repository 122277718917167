import { state } from '@/main';
import axios from 'axios';

export const getNftHoldings = async () => {
    try {
        const url = `${state.stacksApi}/extended/v1/tokens/nft/holdings?principal=${state.stacksUser}&limit=200`;
        const response = await axios.get(url);

        let results;
        let totalAmount = response.data.total;
        let offset = 200;

        if (totalAmount <= 200) {
            results = response.data.results;
        } else {
            results = [...response.data.results];

            const times = Math.ceil(totalAmount / 200);

            for (let i = 0; i < times; i++) {
                if (offset < totalAmount) {
                    let response = await axios.get(url + `&offset=${offset}`);

                    offset += response.data.results.length;

                    if (offset > 0) {
                        results = [...results, ...response.data.results];
                    }
                }
            }
        }

        const satoshibles = [];

        results.filter((item) => {
            let id = Number(item.value.repr.replace('u', ''));

            if (item.asset_identifier === state.satoshiblesContract + '::Satoshibles' && !satoshibles.includes(id)) {
                satoshibles.push(id);
            }
        });

        const monsters = [];

        results.filter((item) => {
            let id = Number(item.value.repr.replace('u', ''));

            if (item.asset_identifier === state.monstersContract + '::MonsterSatoshibles' && !monsters.includes(id)) {
                monsters.push(id);
            }
        });

        return {
            monsters,
            satoshibles
        };
    } catch (error) {
        console.log('Get monster holdings error: ', error.message);
    }
};