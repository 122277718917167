import { isDev, state } from '@/main';
import { StacksMainnet, StacksTestnet } from '@stacks/network';
import { callReadOnlyFunction, cvToJSON, uintCV } from "@stacks/transactions";

export const isPrimeAvailableForClaim = async (id) => {
    const activeNetwork = isDev ? new StacksTestnet({ url: state.stacksApi }) : new StacksMainnet({ url: state.stacksApi });

    const functionArgs = [uintCV(id)];
    const contract = state.whitelist.primes;

    const options = {
        contractAddress: contract.split(".")[0],
        contractName: contract.split(".")[1],
        functionName: "wl-one-prime-is-minted",
        network: activeNetwork,
        functionArgs,
        senderAddress: state.stacksUser,
    };

    const res = await callReadOnlyFunction(options);

    return cvToJSON(res).value === false;
};