import { createWebHistory, createRouter } from "vue-router";
import Home from "@/js/views/Home.vue";
import About from "@/js/views/About.vue";
import Monsters from "@/js/views/Monsters.vue";
import NotFound from "@/js/views/NotFound.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/about",
        name: "About",
        component: About,
    },
    {
        path: "/monsters",
        name: "Monsters",
        component: Monsters,
    },
    {
        path: "/:catchAll(.*)",
        component: NotFound,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;