<template>
    <div id="progress">
        <div class="page-width">
            <div class="progress-bar">
                <div class="info">
                    Minted: {{ state.currentProgress }} / {{ total }}
                </div>
                <div class="label"></div>
                <div class="value" :style="width" />
            </div>

        </div>
    </div>
</template>

<script>
import { isDev, state } from '@/main';
import { StacksMainnet, StacksTestnet } from '@stacks/network';
import { callReadOnlyFunction } from '@stacks/transactions';
import { ref, computed } from 'vue';

const getLastedId = async () => {
    const activeNetwork = isDev ? new StacksTestnet({ url: state.stacksApi }) : new StacksMainnet({ url: state.stacksApi });
    const functionArgs = [];

    const options = {
        contractAddress: state.monstersContract.split(".")[0],
        contractName: state.monstersContract.split(".")[1],
        functionName: "get-last-token-id",
        network: activeNetwork,
        functionArgs,
        senderAddress: state.stacksUser,
    };

    const res = await callReadOnlyFunction(options);

    return Number(res.value.value);
};

export default {
    async setup() {
        const total = ref(6666);

        setInterval(async () => {
            state.currentProgress = await getLastedId();
        }, 8000);

        state.currentProgress = await getLastedId();

        const width = computed(() => {
            const percentage = (100 / total.value * state.currentProgress).toFixed(0);

            return { width: `${percentage}%` };
        });

        return {
            total,
            width,
            state,
        };
    }
};
</script>
