<template>
    <div id="checker" v-if="state.showChecker">
        <form @submit.prevent="runChecker" @change="showCheckerResult = false;">
            <span class="select">
                <select v-model="checkerCollection" @change="resetChecker" :disabled="checkerLoading">
                    <option value="satoshibles">Satoshibles</option>
                    <option value="monsters">Monster Satoshibles</option>
                </select>
            </span>
            <input type="number" v-model="checkerId" :disabled="checkerLoading" :placeholder="checkerCollection === 'satoshibles' ? 'e.g. 27' : 'e.g. 666001'" required>
            <button type="submit" :disabled="checkerLoading" class="button primary">Check ID</button>
        </form>
        <div v-if="showCheckerError" class="result negative">
            Oops, something went wrong. Try again.
        </div>
        <div v-if="showCheckerResult" class="result" :class="{negative: !checkerResult}">
            <div v-if="checkerResult">
                {{ checkerCollection === 'satoshibles' ? 'Satoshible' : 'Monster Satoshible' }} #{{ checkerId }} can be used for claiming! <span class="emoji">🎉</span>
            </div>
            <div v-else>
                {{ checkerCollection === 'satoshibles' ? 'Satoshible' : 'Monster Satoshible' }} #{{ checkerId }} can not be used for claiming! <span class="emoji">🥲</span>
            </div>
        </div>
    </div>
    <div class="claim-content">
        <h2>Monster Claim <span>Available NFTs ({{ totalClaimable }}/{{ holdings.length }})</span></h2>
        <div id="holdings">
            <div v-if="holdings.length > 0" class="wrapper">
                <div class="description">
                    <div v-if="state.claimMode === 1">
                        <p>You can use any Prime Monster or Diamond Satoshible you own to claim 2 new monsters each.</p>
                        <p><strong>Note:</strong> <span class="highlight">After 24 hours</span>, the claim will open to any other Monster or Satoshible holder.</p>
                    </div>
                    <div v-else>
                        <p>You can use any Prime Monster or Diamond Satoshible you own to claim 2 new Monsters each.</p>
                        <p>Any other Monster or Satoshible will be able to claim 1 each.</p>
                    </div>
                </div>
                <div class="collection">
                    <ul class="items">
                        <li v-for="item of holdings" @click="item.pendingTx ? '' : setActiveItem(item)" :class="{claimable: item.claimable && !item.pendingTx, pending: item.pendingTx}">
                            <component
                                :is="!item.pendingTx ? 'span' : 'a'"
                                :href="!item.pendingTx ? null : `https://explorer.stacks.co/txid/${item.pendingTx}?chain=${network}`"
                                :target="!item.pendingTx ? null : '_blank'"
                            >
                                <span class="tag" v-if="item.claimable && (item.isPrime || item.isDiamond)">Claim x2</span>
                                <Spinner v-if="item.pendingTx" size="large" />
                                <img :src="item.imageUrl" :alt="`${item.singularName} #${item.id}`" :key="item.id">
                            </component>
                        </li>
                    </ul>
                </div>
            </div>
            <div v-else class="wrapper" style="width: 100%;">
                <div class="description">
                    <p>It looks like you do not have any Satoshibles or Monster Satoshibles in this Stacks wallet.</p>
                    <p>Please try another wallet, or if you are not currently a holder you can buy on secondary and then claim.</p>

                    <p class="note">Tip: Make sure to use the ID Check button above before you purchase, you do not want to purchase an NFT that has already been used for claiming.</p>

                    <div class="buttons">
                        <p><a href="https://gamma.io/collections/satoshibles" class="button primary large" target="_blank">Buy Satoshibles on Gamma</a></p>
                        <p><a href="https://gamma.io/collections/monster-satoshibles" class="button primary large" target="_blank">Buy Monsters on Gamma</a></p>
                    </div>
                </div>
            </div>
        </div>

        <Modal v-if="state.activeItem" @close="closeModal" :disable-closing-outside="true">
            <ClaimModal :item="state.activeItem" :close="closeModal" />
        </Modal>
    </div>
</template>

<script>
import { ref } from 'vue';
import { isDev, state } from '@/main';
import Modal from '@/js/components/Modal';
import Icon from '@/js/components/Icon';
import ClaimModal from '@/js/components/ClaimModal';
import { getNftHoldings } from '@/js/helpers/getNftHoldings';
import { confetti } from '@/js/helpers/confetti';
import { isDiamondAvailableForClaim } from '@/js/helpers/isDiamondAvailableForClaim';
import { array as diamondsData } from '@/js/data/diamonds';
import { burned } from '@/js/data/burned';
import { isSatAvailableForClaim } from '@/js/helpers/isSatAvailableForClaim';
import { isPrimeAvailableForClaim } from '@/js/helpers/isPrimeAvailableForClaim';
import { isMonsterAvailableForClaim } from '@/js/helpers/isMonsterAvailableForClaim';
import { getOwner } from '@/js/helpers/getOwner';
import { firstBy } from "thenby";
import axios from 'axios';
import Spinner from '@/js/components/Spinner';

export default {
    components: {
        Spinner,
        Modal,
        ClaimModal,
        Icon
    },

    async setup() {
        const monsters = ref([]);
        const checkerCollection = ref('satoshibles');
        const checkerId = ref(null);
        const checkerLoading = ref(false);
        const checkerResult = ref(false);
        const showCheckerResult = ref(false);
        const showCheckerError = ref(false);
        const satoshibles = ref([]);
        const totalClaimable = ref(0);
        const network = ref(isDev ? 'testnet' : 'mainnet');
        const holdings = ref([]);

        const response = await getNftHoldings();

        monsters.value = response.monsters;
        satoshibles.value = response.satoshibles;

        state.loadingTotal = monsters.value.length + satoshibles.value.length;

        const sortOrder = () => {
            holdings.value = holdings.value.sort(
                firstBy('claimable')
                    .thenBy("pendingTx")
                    .thenBy("isPrime")
                    .thenBy("isDiamond")
                    .thenBy((v) => v.singularName === 'Satoshible')
                    .thenBy((v) => v.singularName === 'Monster Satoshible' && v.id < 3305)
            ).reverse();
        };

        for (let id of satoshibles.value) {
            const isDiamond = diamondsData.includes(id);

            state.loadingCurrent++;

            holdings.value.push({
                id,
                imageUrl: `https://satoshibles.com/token/${id}/tiny/image.png`,
                singularName: 'Satoshible',
                collection: 'satoshibles',
                isPrime: false,
                pendingTx: null,
                isDiamond,
                claimable: isDiamond ? await isDiamondAvailableForClaim(id) : await isSatAvailableForClaim(id)
            });
        }

        for (let id of monsters.value) {
            const isPrime = id >= 666001 && id <= 666666;
            const isStacksNFT = id > 3305 && id < 666001;

            state.loadingCurrent++;

            holdings.value.push({
                id,
                imageUrl: isStacksNFT ? 'https://satoshibles.com/monsters/hidden.gif' : `https://satoshibles.com/monsters/token/${id}/tiny/image.png`,
                singularName: 'Monster Satoshible',
                collection: 'monsters',
                isPrime,
                pendingTx: null,
                isDiamond: false,
                claimable: isStacksNFT ? false : isPrime ? await isPrimeAvailableForClaim(id) : await isMonsterAvailableForClaim(id)
            });
        }

        totalClaimable.value = holdings.value.filter(item => item.claimable).length;

        const setActiveItem = (item) => {
            if (!item.claimable || item.isPending) return;

            state.activeItem = item;
        };

        let pendingIDs = ref([]);

        const refreshPending = async () => {
            if (state.overridePending) {
                return;
            }

            const pending = await axios.get(`${state.stacksApi}/extended/v1/tx/mempool?sender_address=${state.stacksUser.split('.')[0]}&cache=${new Date().getTime()}`);

            pendingIDs.value = [];

            for (const tx of pending.data.results) {
                if (tx.contract_call && tx.contract_call.contract_id === state.monstersContract) {
                    const collection = tx.contract_call.function_args[2].repr.replace('\'', '') === state.satoshiblesContract ? 'satoshibles' : 'monsters';

                    pendingIDs.value.push({
                        id: Number(tx.contract_call.function_args[1].repr.replace('u', '')),
                        tx: tx.tx_id,
                        collection
                    });
                }
            }

            for (const nft of holdings.value) {
                if (pendingIDs.value.length < 1) {
                    nft.pendingTx = null;
                } else {
                    for (const pend of pendingIDs.value) {
                        if (pend.id === nft.id && pend.collection === nft.collection) {
                            nft.pendingTx = pend.tx;
                        }
                    }
                }
            }
        };

        await refreshPending();

        sortOrder();

        let timer;
        const interval = 10000;

        timer = setInterval(async () => {
            await refreshPending();
        }, interval);

        const closeModal = async () => {
            clearInterval(timer);

            await refreshPending();

            timer = setInterval(async () => {
                await refreshPending();
            }, interval);

            state.activeItem = null;
        };

        const runChecker = async () => {
            checkerLoading.value = true;
            showCheckerResult.value = false;
            showCheckerError.value = false;

            try {
                let bool = false;

                if (checkerCollection.value === 'satoshibles') {
                    console.log('Checking Sat....');

                    if (checkerId.value < 1 || checkerId.value > 5000) {
                        bool = false;
                    } else {
                        let hasPending = false;

                        const owner = await getOwner(state.satoshiblesContract, checkerId.value);

                        if (owner !== state.satoshiblesBridgeContract) {
                            const pending = await axios.get(`${state.stacksApi}/extended/v1/tx/mempool?sender_address=${owner}&cache=${new Date().getTime()}`);

                            if (pending.data.results.length > 0) {
                                for (const tx of pending.data.results) {
                                    if (tx.contract_call && tx.contract_call.contract_id === state.monstersContract) {
                                        const collection = tx.contract_call.function_args[2].repr.replace('\'', '') === state.satoshiblesContract ? 'satoshibles' : 'monsters';
                                        const id = Number(tx.contract_call.function_args[1].repr.replace('u', ''));

                                        if (collection === 'satoshibles' && id === checkerId.value) {
                                            hasPending = true;
                                        }
                                    }
                                }
                            }
                        }

                        if (hasPending) {
                            bool = false;
                        } else {
                            const isDiamond = diamondsData.includes(checkerId.value);

                            bool = isDiamond ? await isDiamondAvailableForClaim(checkerId.value) : await isSatAvailableForClaim(checkerId.value, true);
                        }
                    }

                } else {
                    console.log('Checking Monster...');

                    if (burned.includes(checkerId.value) || checkerId.value < 1 || checkerId.value > 666666) {
                        bool = false;
                    } else {
                        let hasPending = false;

                        const owner = await getOwner(state.monstersContract, checkerId.value);

                        if (owner !== state.monsterBridgeContract) {
                            const pending = await axios.get(`${state.stacksApi}/extended/v1/tx/mempool?sender_address=${owner}&cache=${new Date().getTime()}`);

                            if (pending.data.results.length > 0) {
                                for (const tx of pending.data.results) {
                                    if (tx.contract_call && tx.contract_call.contract_id === state.monstersContract) {
                                        const collection = tx.contract_call.function_args[2].repr.replace('\'', '') === state.satoshiblesContract ? 'satoshibles' : 'monsters';
                                        const id = Number(tx.contract_call.function_args[1].repr.replace('u', ''));

                                        if (collection === 'monsters' && id === checkerId.value) {
                                            hasPending = true;
                                        }
                                    }
                                }
                            }
                        }

                        if (hasPending) {
                            bool = false;
                        } else {
                            const isPrime = checkerId.value >= 666001 && checkerId.value <= 666666;
                            const isStacksNFT = checkerId.value > 3305 && checkerId.value < 666001;

                            bool = isStacksNFT ? false : isPrime ? await isPrimeAvailableForClaim(checkerId.value) : await isMonsterAvailableForClaim(checkerId.value, true);
                        }
                    }
                }

                if (bool) {
                    confetti.fire();
                }

                checkerLoading.value = false;
                checkerResult.value = bool;
                showCheckerResult.value = true;

            } catch (e) {
                console.error(e);
                checkerLoading.value = false;
                showCheckerError.value = true;
            }
        };

        const resetChecker = () => {
            checkerId.value = null;
            checkerResult.value = false;
            showCheckerResult.value = false;
        };

        return {
            state,
            closeModal,
            checkerCollection,
            checkerId,
            network,
            checkerLoading,
            showCheckerResult,
            checkerResult,
            holdings,
            showCheckerError,
            resetChecker,
            runChecker,
            totalClaimable,
            setActiveItem
        };
    }
};
</script>