<template>
    <div id="claim-modal">
        <div class="loader" v-if="isLoading">
            <Spinner size="huge"/>
        </div>
        <h3>Claim using {{ item.singularName }} #{{ item.id }}</h3>
        <p><img :src="item.imageUrl" :alt="`${item.singularName} #${item.id}`"></p>

        <div v-if="item.pendingTx" class="button-wrapper">
            <h4 v-if="item.isPrime || item.isDiamond">Your free Monster NFTs are on the way!</h4>
            <h4 v-else>Your free Monster NFT is on the way!</h4>
            <p>Your transaction has been successfully sent to the Stacks blockchain and is now being processed. You can go to the explorer to check if it has been confirmed.</p>
            <a :href="`https://explorer.stacks.co/txid/${item.pendingTx}?chain=${network}`" class="button primary" target="_blank">View Claim Transaction</a>
        </div>
        <div v-else class="button-wrapper">
            <button @click="close" class="button">Go Back</button>
            <button v-if="item.isPrime || item.isDiamond" @click="claimTwo" class="button primary">Claim Monsters (2)</button>
            <button v-else @click="claimOne" class="button primary">Claim Monster (1)</button>
        </div>
    </div>
</template>

<script>
import { isDev, state } from '@/main';
import { openContractCall } from '@stacks/connect';
import { StacksMainnet, StacksTestnet } from '@stacks/network';
import { PostConditionMode, uintCV, contractPrincipalCV } from '@stacks/transactions';
import { principalCV } from '@stacks/transactions/dist/clarity/types/principalCV';
import { ref, watch } from 'vue';
import { confetti } from '@/js/helpers/confetti';
import Spinner from '@/js/components/Spinner';

export default {
    components: { Spinner },
    props: {
        close: {
            type: Function
        },
        item: {
            type: String
        }
    },

    async setup(props) {
        const isLoading = ref(false);
        const network = isDev ? new StacksTestnet({ url: state.stacksApi }) : new StacksMainnet({ url: state.stacksApi });

        const claim = async (fn) => {
            const postConditions = [];
            const postConditionMode = PostConditionMode.Deny;
            const nftContract = state.activeItem.singularName === 'Satoshible' ? state.satoshiblesContract : state.monstersContract;

            isLoading.value = true;

            const functionArgs = [
                principalCV(state.stacksUser),
                uintCV(state.activeItem.id),
                contractPrincipalCV(nftContract.split(".")[0], nftContract.split(".")[1])
            ];

            const contractAddress = state.monstersContract.split(".")[0];
            const contractName = state.monstersContract.split(".")[1];

            const options = {
                network,
                contractAddress,
                contractName,
                functionName: fn,
                functionArgs,
                postConditionMode,
                postConditions,
                appDetails: {
                    name: "Satoshibles",
                    icon: state.appIcon,
                },
                onCancel: () => {
                    console.log('Cancelled...');
                    isLoading.value = false;
                },
                onFinish: (data) => {
                    console.log("Stacks Transaction:", data.stacksTransaction);
                    console.log("Transaction ID:", data.txId);
                },
            };

            await openContractCall(options);
        };

        const claimTwo = async () => {
            await claim("mint-two-free");
        };

        const claimOne = async () => {
            await claim("mint-one-free");
        };

        watch(() => props.item.pendingTx, () => {
            isLoading.value = false;
            confetti.fire();
        });

        return {
            state,
            claimOne,
            network,
            isLoading,
            claimTwo
        };
    }
};
</script>