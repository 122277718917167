import confettiCanvas from 'canvas-confetti';

const count = 200;
const defaults = {
    origin: {y: 0.5},
    zIndex: 999999
};

function play(particleRatio, opts) {
    confettiCanvas(Object.assign({}, defaults, opts, {
        particleCount: Math.floor(count * particleRatio)
    }));
}

export const confetti = {
    fireworks: () => {
        let duration = 15 * 1000;
        let animationEnd = Date.now() + duration;
        let defaults = {startVelocity: 30, spread: 360, ticks: 60, zIndex: 0};

        function randomInRange(min, max) {
            return Math.random() * (max - min) + min;
        }

        let interval = setInterval(function () {
            let timeLeft = animationEnd - Date.now();

            if (timeLeft <= 0) {
                return clearInterval(interval);
            }

            let particleCount = 50 * (timeLeft / duration);
            // since particles fall down, start a bit higher than random
            confettiCanvas(Object.assign({}, defaults, {particleCount, origin: {x: randomInRange(0.1, 0.3), y: Math.random() - 0.2}}));
            confettiCanvas(Object.assign({}, defaults, {particleCount, origin: {x: randomInRange(0.7, 0.9), y: Math.random() - 0.2}}));
        }, 250);
    },
    fire: () => {
        play(0.25, {
            spread: 26,
            startVelocity: 55,
        });
        play(0.2, {
            spread: 60,
        });
        play(0.35, {
            spread: 100,
            decay: 0.91,
            scalar: 0.8
        });
        play(0.1, {
            spread: 120,
            startVelocity: 25,
            decay: 0.92,
            scalar: 1.2
        });
        play(0.1, {
            spread: 120,
            startVelocity: 45,
        });
    }
};
