import { createApp, reactive } from 'vue';
import App from './js/App.vue';
import router from './js/router';
import { Buffer } from "@stacks/common";

global.Buffer = Buffer;

fetch('/icons.svg').then(response => response.text()).then(data => {
    document.body.insertAdjacentHTML('afterbegin', data);
});

export const isDev = process.env.NODE_ENV === "development";

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const override = urlParams.get('override');

export const state = reactive({
    appIsOpen: true,
    overridePending: override,
    loadingCurrent: 0,
    loadingTotal: 0,
    currentProgress: 0,
    mintOver: true,
    stacksUser: null,
    ethUser: null,
    userData: null,
    showChecker: false,
    activeItem: null,
    appIcon: "https://satoshibles.com/images/fav.png",
    claimMode: 2,
    loadingMessage: 'Loading...',
    monstersContract: isDev ? "ST11T7WK8T6E80AK8G67G46JVNM4MNMYGDD20Y4QW.monster-satoshibles" : "SP6P4EJF0VG8V0RB3TQQKJBHDQKEF6NVRD1KZE3C.monster-satoshibles",
    satoshiblesContract: isDev ? "ST2507VNQZC9VBXM7X7KB4SF4QJDJRSWHG6ERHWB7.satoshibles-v3" : "SP6P4EJF0VG8V0RB3TQQKJBHDQKEF6NVRD1KZE3C.satoshibles",
    satoshiblesBridgeContract: isDev ? "ST11T7WK8T6E80AK8G67G46JVNM4MNMYGDD20Y4QW.stacksbridge-satoshibles-v2" : "SP6P4EJF0VG8V0RB3TQQKJBHDQKEF6NVRD1KZE3C.stacksbridge-satoshibles-v4",
    monsterBridgeContract: isDev ? "ST11T7WK8T6E80AK8G67G46JVNM4MNMYGDD20Y4QW.stacksbridge-monster-satoshibles" : "SP6P4EJF0VG8V0RB3TQQKJBHDQKEF6NVRD1KZE3C.stacksbridge-monster-satoshibles",
    stacksApi: isDev ? 'https://solemn-evocative-diagram.stacks-testnet.quiknode.pro/763bf5686ed76079da40152d39ea86b0032da41b' : 'https://evocative-palpable-wildflower.stacks-mainnet.quiknode.pro/c52afa503c2fbb2a1937f910203d6fc0aafcde68',
    whitelist: {
        primes: isDev ? 'ST11T7WK8T6E80AK8G67G46JVNM4MNMYGDD20Y4QW.monsters-wl-one-prime' : 'SP6P4EJF0VG8V0RB3TQQKJBHDQKEF6NVRD1KZE3C.monsters-wl-one-prime',
        diamond: isDev ? 'ST11T7WK8T6E80AK8G67G46JVNM4MNMYGDD20Y4QW.monsters-wl-one-diamond' : 'SP6P4EJF0VG8V0RB3TQQKJBHDQKEF6NVRD1KZE3C.monsters-wl-one-diamond',
        normal: isDev ? 'ST11T7WK8T6E80AK8G67G46JVNM4MNMYGDD20Y4QW.monsters-wl-two' : 'SP6P4EJF0VG8V0RB3TQQKJBHDQKEF6NVRD1KZE3C.monsters-wl-two'
    }
});

createApp(App).use(router).mount('#app');
